export default class Survey {
  constructor() {
    this.id = null;
    this.name = null;
    this.color = null;
    this.description = null;
    this.sections = [];
    this.questionCount = 0;
    this.publishCount = 0;
  }
}
