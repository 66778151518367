import gql from "graphql-tag";

export const PENDING_SURVEY_RESULTS = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      pendingSurveyResults {
        id
        createdDateTime
        publishedSurvey {
          survey {
            id
            name
            color
          }
          endDateTime
          publishDateTime
        }
      }
    }
  }
`;

export const SURVEY_RESULT = gql`
  query surveyResult($id: ID!) {
    surveyResult(id: $id) {
      id
      answers {
        question {
          id
        }
        questionChoices {
          id
          rank
          name
          other
          imageUrl
        }
        answer
        answer2
      }
      publishedSurvey {
        endDateTime
        survey {
          id
          color
          name
          description
          questionCount
          sections {
            id
            name
            description
            rank
            questions {
              id
              name
              rank
              answerType
              imageUrl
              required
              firstTag
              lastTag
              choices {
                id
                name
                imageUrl
                other
                rank
              }
            }
          }
        }
      }
    }
  }
`;
